import { Component, OnInit } from '@angular/core';
import { Contribution, Global } from 'src/app/models';
import { Store, select } from '@ngrx/store';
import { AppState, InvalidFieldsSelector, incrementValidatedToPage, incrementActivePage, setActivePage } from 'src/app/store';
import { Observable } from 'rxjs';
import { FormGroupState, AddArrayControlAction, RemoveArrayControlAction } from 'ngrx-forms';

@Component({
  selector: 'app-contributions',
  templateUrl: './contributions.component.html',
  styleUrls: ['./contributions.component.scss']
})
export class ContributionsComponent implements OnInit {
  private appStore: Store<AppState>;
  global$: Observable<Global>;
  contributionForm$: Observable<FormGroupState<Contribution>>;
  appErrors$: Observable<number>;
  canAdvance: number;
  ValidatedToPage: number;

  constructor(private store: Store<AppState>, private invalidFieldsSelector: InvalidFieldsSelector) {
    this.appStore = store;
    this.global$ = store.pipe(
      select(state => state.global as Global),
    );
    this.appErrors$ = invalidFieldsSelector.appErrors$;
    this.contributionForm$ = store.pipe(
      select(state => state.form.controls.contribution as FormGroupState<Contribution>)
    )

    const vm = this;
    this.global$.subscribe(async (globals) => {
      vm.ValidatedToPage = globals.ValidatedToPage
    });
  }

  addContribution(): void {
    this.appStore.dispatch(
      new AddArrayControlAction(
        'form.contribution.income',
        {
            name: '', value: null, isDefault: false,
            nameInputTranslationScope: 'form.contribution.jobTitle',
            priceInputTranslationScope: 'form.contribution.jobPrice'
        }
      ));

      let focusObj = 0;
      this.appStore.subscribe((state) => {
        focusObj = state.form.controls.contribution.controls.income.controls.length - 1
      })

      let target = 'form.contribution.income'
      window.setTimeout(() => {
        const element:HTMLElement = document.getElementById(`${target}.${focusObj}.name`) || document.getElementById(`${target}.${focusObj}.value`)
        element.focus();
      }, 0);
  }

  removeContribution(index: number): void {
    const target = 'form.contribution.income'

    this.appStore.dispatch(
      new RemoveArrayControlAction(
        target,
        index
      ));

    document.getElementById(`${target}.addMore`).focus()
  }

  advanceFormState(): void {
    const vm = this;
    // increment store
    if (this.canAdvance) {
      if (vm.ValidatedToPage <= 3) vm.appStore.dispatch(incrementValidatedToPage());
      vm.appStore.dispatch(setActivePage({ activePage: 4 }));
    }
  }

  setupErrorWatchers(vm): void {
    vm.canAdvance = 1;
  }

  incrementValidatedToPage() {
    this.appStore.dispatch(incrementValidatedToPage())
  }

  setActivePage(newIndex: number) {
    let vm = this;
    let isInBounds = (newIndex <= vm.ValidatedToPage)
    if (isInBounds) {
      let activePage: number = newIndex;
      vm.appStore.dispatch(setActivePage({ activePage: activePage }))
    }
  }

  ngOnInit(): void {
    const vm = this;
    this.setupErrorWatchers(vm);

    // This is a skippable page
    vm.canAdvance = 1;
  }

}
