import {AbstractControlState, FormGroupControls, updateGroup, validate} from 'ngrx-forms';
import {isRequired, withinRange} from './utils';
import {School} from '../models';
import {RootForm} from './root.reducer';

export const initialSchoolState = {
    tuition: null,
};

export const schoolGroupValidation = (rootControl: FormGroupControls<RootForm>) => updateGroup<School>({
    tuition: (tuition: AbstractControlState<number>) =>
        validate<number>(
            tuition,
            isRequired(rootControl.config.controls.annualTuitionRequired.value),
            withinRange(
                rootControl.config.controls.minAnnualTuition.value,
                rootControl.config.controls.maxAnnualTuition.value
            )
        ),
});
