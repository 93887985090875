import { LanguageService } from '../service/language.service'

const languageService = new LanguageService;
const activeLang = languageService.getLang();
let langCode = 'ca-en';

if (activeLang === 'en' || activeLang === 'fr') {
    langCode = `ca-${activeLang}`
} else if (activeLang === 'zh' || activeLang === 'zt') {
    langCode = `cn-${activeLang}`
} else {
    langCode = 'ca-en'
}

// Setup Analytics Tag
const tms_tag = {
    brand: 'tdct',
    channel: langCode, //EN or FR
    pageName: 'student-budget-calculator-school-step1', // Set pageName for each screen,
    eVar21: 'tdct:p:student:budget:calculator',
    prop12: 'not-authenticated', // 'authenticated'
    // prop17: '', // click event
};

window['tms_tag'] = tms_tag;

export default tms_tag
