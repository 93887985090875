import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState, InvalidFieldsSelector, incrementValidatedToPage, incrementActivePage, setActivePage } from 'src/app/store';
import { Observable } from 'rxjs';
import { FormGroupState } from 'ngrx-forms';
import { Housing, Global, HousingChoice } from 'src/app/models';

@Component({
  selector: 'app-housing',
  templateUrl: './housing.component.html',
  styleUrls: ['./housing.component.scss']
})
export class HousingComponent implements OnInit {
  private appStore: Store<AppState>;
  housingForm$: Observable<FormGroupState<Housing>>;
  global$: Observable<Global>;
  appErrors$: Observable<number>;
  canAdvance: number;
  ValidatedToPage: number;
  housingChoices: {
    home: HousingChoice,
    residence: HousingChoice,
    renting: HousingChoice
  };
  housingChoice: HousingChoice;

  constructor(private store: Store<AppState>, private invalidFieldsSelector: InvalidFieldsSelector) {
    this.appStore = store;
    this.global$ = store.pipe(
      select(state => state.global as Global),
    );
    this.appErrors$ = invalidFieldsSelector.appErrors$;
    this.housingForm$ = store.pipe(
      select(state => state.form.controls.housing as FormGroupState<Housing>),
    );
    this.housingChoices = {
      home: HousingChoice.home,
      residence: HousingChoice.residence,
      renting: HousingChoice.renting
    };
    this.housingChoice = HousingChoice.none;

    const vm = this;
    this.global$.subscribe(async (globals) => {
      vm.ValidatedToPage = globals.ValidatedToPage
    });
  }

  advanceFormState(): void {
    const vm = this;
    // increment store
    if (this.canAdvance) {
      if (vm.ValidatedToPage <= 1) vm.appStore.dispatch(incrementValidatedToPage());
      vm.appStore.dispatch(setActivePage({ activePage: 2 }));
    }
  }

  setupErrorWatchers(vm): void {
    vm.canAdvance = 1;
    vm.housingForm$.subscribe((form: FormGroupState<Housing>) => {
      vm.housingChoice = form.controls.choice.value
    });
  }

  incrementValidatedToPage() {
    this.appStore.dispatch(incrementValidatedToPage())
  }

  setActivePage(newIndex: number) {
    let vm = this;
    let isInBounds = (newIndex <= vm.ValidatedToPage)
    if (isInBounds) {
      let activePage: number = newIndex;
      vm.appStore.dispatch(setActivePage({ activePage: activePage }))
    }
  }

  ngOnInit(): void {
    const vm = this;
    this.setupErrorWatchers(vm);

    // This is a skippable page
    vm.canAdvance = 1;
  }

}
