import { createReducer, on } from '@ngrx/store';
import * as GlobalActions from './global.actions';
import { Global } from '../models/global';

export const initialGlobalState: Global = {
    activePage: 0,
    ValidatedToPage: 0,
    pageOrder: [
        'School',
        'Housing',
        'Expenses',
        'Contributions',
        'Results',
    ],
    TotalCost: 0,
    TotalContribution: 0,
};

export const globalReducer = createReducer(
    initialGlobalState,
    on(GlobalActions.incrementActivePage, state => ({ ...state, activePage: state.activePage + 1 })),
    on(GlobalActions.incrementValidatedToPage, state => ({ ...state, ValidatedToPage: state.ValidatedToPage + 1 })),
    on(GlobalActions.setActivePage, (state, { activePage }) => ({ ...state, activePage: activePage })),
    on(GlobalActions.setValidatedToPage, (state, { ValidatedToPage }) => ({ ...state, ValidatedToPage: ValidatedToPage })),
    on(GlobalActions.setTotal, (state, { TotalCost, TotalContribution }) => ({ ...state, TotalCost: TotalCost, TotalContribution: TotalContribution}))
);
