import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { FormGroupState, AbstractControlState } from 'ngrx-forms';

import { AppState, SumCostsSelector, setTotal } from '../../store';
import { HousingChoice } from '../../models';

@Component({
    selector: 'app-finances-chart',
    templateUrl: './finances-chart.component.html',
    styleUrls: ['./finances-chart.component.scss']
})
export class FinancesChartComponent implements OnInit {
    private appStore: Store<AppState>;
    schoolSum: number;
    housingSum: number;
    grocerySum: number;
    entertainmentSum: number;
    commonSum: number;
    additionalSum: number;
    otherCostsSum: number;
    totalCost: number;
    scholarshipsSum: number;
    grantsSum: number;
    otherContributionsSum: number;
    savingsSum: number;
    incomeSum: number;
    totalContributions: number;
    maxSize: number;
    numWeeks: number;
    numMonths: number;
    prevTotalCost: number;
    prevTotalContribution: number;

    costInflation: number;
    contributionInflation: number;

    chartHeight = 340;
    readonly SEGMENT_MIN_HEIGHT = 38;
    readonly SEGMENT_MARGIN = 5;
    readonly MAX_SIZE = 80000;

    constructor(private store: Store<AppState>, private sumCostsSelector: SumCostsSelector) {
        this.appStore = store;
        this.schoolSum = 0;
        this.housingSum = 0;
        this.housingSum = 0;
        this.grocerySum = 0;
        this.entertainmentSum = 0;
        this.commonSum = 0;
        this.additionalSum = 0;
        this.otherCostsSum = 0;
        this.totalCost = 0;
        this.scholarshipsSum = 0;
        this.grantsSum = 0;
        this.otherContributionsSum = 0;
        this.savingsSum = 0;
        this.incomeSum = 0;
        this.totalContributions = 0;
        this.maxSize = this.MAX_SIZE;
        this.numWeeks = 16 * 2;
        this.numMonths = 8;
        this.prevTotalCost = 0;
        this.prevTotalContribution = 0;
        this.costInflation = 1;
        this.contributionInflation = 1;

        window.addEventListener('resize', this.updateChart);
    }

    /**
     * Calculate the visual amount represented by the chart segments
     * i.e. if the amount is too small, use the amount represented by the minimum chart segment
     * e.g. the amount for housing is 100, the chart segment is limited to a min height of
     *      1/10 of the chart. The visual amount is hence 1/10 of MAX_SIZE
     */
    getVisualAmount(raw: number[], segments: number): number {
        const vm = this;
        if (!raw) {
            return 0;
        }
        return raw
                .map(r => r > 0 ? Math.max(r, (vm.SEGMENT_MIN_HEIGHT / vm.chartHeight) * vm.maxSize) : 0)
                .reduce((a, b) => a + b, 0)
            // Height of margins between segments
            + (segments > 1 ? ((vm.SEGMENT_MARGIN / vm.chartHeight) * vm.maxSize * (segments - 1)) : 0);
    }

    setTotal(totalCost: number, totalContributions: number): void {
        const vm = this;
        if (totalCost !== vm.prevTotalCost || totalContributions !== vm.prevTotalContribution) {
            vm.appStore.dispatch(setTotal({TotalCost: totalCost, TotalContribution: totalContributions}));
            vm.prevTotalCost = totalCost;
            vm.prevTotalContribution = totalContributions;
        }
    }

    sumValues(control: AbstractControlState<any>): number {
        const vm = this;
        const subControl = (control as FormGroupState<any>).controls;
        if (control.isPristine) {
            return 0;
        }
        if (!subControl) {
            return ((control.value && control.value.toString() !== '')
                ? parseFloat(control.value.toString())
                : 0);
        }
        return Object.keys(subControl).reduce((value, key) => {
            return vm.sumValues(subControl[key]) +
                ((value && value.toString() !== '')
                    ? parseFloat(value.toString())
                    : 0);
        }, 0);
    }

    sumArray = (accumulator, currentValue) =>
        currentValue.value.value && typeof currentValue.value.value === 'string' && currentValue.value.value !== ''
            ? accumulator + parseFloat(currentValue.value.value)
            : accumulator + 0

    updateChart = () => {
        const vm = this;
        const element = document.getElementById('barchart-container');
        vm.chartHeight = element.getBoundingClientRect().height;
        vm.maxSize = vm.MAX_SIZE;

        // Calculate the "price inflation" caused by min segment height
        const costs = [vm.schoolSum, vm.housingSum, vm.grocerySum, vm.entertainmentSum, vm.otherCostsSum];
        const contributions = [vm.scholarshipsSum, vm.grantsSum, vm.otherContributionsSum, vm.savingsSum, vm.incomeSum];
        const costSegments = costs.reduce((s, n) => n ? s + 1 : s, 0);
        const contributionSegments = contributions.reduce((s, n) => n ? s + 1 : s, 0);
        const visualTotalCost = this.getVisualAmount(costs, costSegments);
        const visualTotalContribution = this.getVisualAmount(contributions, contributionSegments);
        vm.costInflation = !vm.totalCost ? 1 : (visualTotalCost / vm.totalCost);
        vm.contributionInflation = !vm.totalContributions ? 1 : (visualTotalContribution / vm.totalContributions);

        const largerChart = Math.max(visualTotalCost, visualTotalContribution);
        vm.maxSize = Math.max(vm.MAX_SIZE, largerChart);
    }

    ngOnInit(): void {
        const vm = this;

        // Do to the math in the math in the template and ts,
        // The values must be tabulated here.

        vm.appStore.subscribe((state: AppState) => {
            // Costs
            vm.schoolSum = vm.sumValues(state.form.controls.school);

            const housingChoice = state.form.controls.housing.controls.choice.value;

            vm.housingSum = 0;
            switch (housingChoice) {
                case HousingChoice.home:
                    vm.housingSum = vm.sumValues(state.form.controls.housing.controls.home);
                    break;
                case HousingChoice.residence:
                    vm.housingSum = vm.sumValues(state.form.controls.housing.controls.residence);
                    break;
                case HousingChoice.renting:
                    vm.housingSum = vm.sumValues(state.form.controls.housing.controls.renting) * vm.numMonths;
                    break;
                default:
                    vm.housingSum = 0;
            }

            vm.grocerySum = (state.form.controls.expenses.controls.grocery.controls.cost.value || 0) * vm.numWeeks;
            vm.entertainmentSum = (state.form.controls.expenses.controls.entertainment.controls.cost.value || 0) * vm.numWeeks;

            vm.commonSum = state.form.controls.expenses.controls.common.controls.commonCost.controls
                .reduce(vm.sumArray, 0) * vm.numMonths;

            vm.additionalSum = state.form.controls.expenses.controls.additional.controls.additionalCost.controls
                .reduce(vm.sumArray, 0);

            vm.otherCostsSum = Number(vm.commonSum) + Number(vm.additionalSum);

            vm.totalCost = Number(vm.schoolSum) + Number(vm.housingSum)
                + Number(vm.grocerySum) + Number(vm.entertainmentSum) + Number(vm.otherCostsSum);

            // Contributions
            vm.scholarshipsSum = state.form.controls.contribution.controls.scholarship.value;
            vm.grantsSum = state.form.controls.contribution.controls.grant.value;
            vm.otherContributionsSum = state.form.controls.contribution.controls.other.value;
            vm.savingsSum = state.form.controls.contribution.controls.savings.value;
            vm.incomeSum = (state.form.controls.contribution.controls.income.controls
                .reduce(vm.sumArray, 0) || 0);

            vm.totalContributions = Number(vm.scholarshipsSum) + Number(vm.grantsSum)
                + Number(vm.otherContributionsSum) + Number(vm.savingsSum) + Number(vm.incomeSum);
            vm.setTotal(vm.totalCost, vm.totalContributions);

            this.updateChart();
        });
    }
}
