import {updateGroup, validate} from 'ngrx-forms';
import {required, greaterThanOrEqualTo, lessThanOrEqualTo} from 'ngrx-forms/validation';

import {Config} from '../models';

export const initialConfigState = {
    housingChoiceRequired: false,
    annualTuitionRequired: true,
    residenceRequired: false,
    rentRequired: false,
    groceryRequired: false,
    entertainmentRequired: false,
    minAnnualTuition: 100,
    maxAnnualTuition: 100000,
    maxHousing: 100000,
    maxMealPlan: 100000,
    maxExpenses: 10000,
};

export const configGroupValidation = updateGroup<Config>({
    minAnnualTuition: validate([required, greaterThanOrEqualTo(0)]),
    maxAnnualTuition: validate([required, greaterThanOrEqualTo(0)]),
    maxHousing: validate([required, greaterThanOrEqualTo(0)]),
    maxMealPlan: validate([required, greaterThanOrEqualTo(0)]),
    maxExpenses: validate([required, greaterThanOrEqualTo(0)]),
});
