import { Component, Input, OnInit } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() formControlState: FormControlState<any>;
  @Input() translationScope: string;
  @Input() isCurrency: boolean;
  @Input() hasSuffix?: boolean;
  @Input() isDynamic?: boolean;
  @Input() dynamicLabel?: any;
  @Input() dynamicFallback?: string;

  constructor() { }

  ngOnInit(): void {
    if (this.isDynamic) {
      this.dynamicLabel = this.formControlState.value
      this.dynamicFallback = this.dynamicFallback
    }
  }

  toArray(errors: object): string[] {
    return Object.keys(errors);
  }
}
