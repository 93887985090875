import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  activeLang: string;

  constructor() {
    this.activeLang = this.getLangFromUrl();
  }

  getLang() {
    this.activeLang = this.getLangFromUrl();
    return this.activeLang;
  }

  getLangFromUrl(): string {
    const windowUrl = window.location.href
    const regex_simpCn = RegExp('(zh)(\.|\-)|\/(zh)')
    const regex_tradCn = RegExp('(zt)(\.|\-)|\/(zt)')
    const regex_en = RegExp('(en)(\.|\-)|(\/en)')
    const regex_fr = RegExp('(fr|outils)(\.|\-)|\/(fr|francais)')

    var simpCn = regex_simpCn.test(windowUrl)
    var tradCn = regex_tradCn.test(windowUrl)
    var en = regex_en.test(windowUrl)
    var fr = regex_fr.test(windowUrl)

    if (!(simpCn || tradCn || en || fr)) {
      simpCn = navigator.language.includes('zh')
      tradCn = navigator.language.includes('zt')
      en = navigator.language.includes('en')
      fr = navigator.language.includes('fr')
    }
    if (simpCn || tradCn) {
      return simpCn ? 'zh' : 'zt'
    } else if (en || fr) {
      return fr ? 'fr' : 'en'
    } else {
      return 'en'
    }
  }
}
