<div class="container px-0" id="results">
  <div class="pt-5 mt-sm-5 col-12 px-0">
    <div *ngIf="difference > 0">
      <h2>{{'result.deficit.title' | transloco }}</h2>
      <p class="d-inline">{{'result.deficit.desc'| transloco: {amount: difference | mask: 'separator.2'} }}</p>
      <a class="d-inline" [href]="'result.hubLink' | transloco" target="_blank" rel="noopener">{{'result.hub' | transloco }}</a>
      <p class="d-inline">{{'result.deficit.desc2'| transloco }}</p>
    </div>
    <div *ngIf="difference < 0">
      <h2>{{'result.surplus.title' | transloco}}</h2>
      <p class="d-inline">{{'result.surplus.desc'  | transloco}}</p>
      <a class="d-inline" [href]="'result.hubLink' | transloco" target="_blank" rel="noopener">{{'result.hub' | transloco }}</a>
      <p class="d-inline">{{'result.surplus.desc2'| transloco }}</p>
    </div>
    <div *ngIf="difference === 0">
      <h2>{{'result.equal.title' | transloco}}</h2>
      <p class="d-inline">{{'result.equal.desc' | transloco}}</p>
      <a class="d-inline" [href]="'result.hubLink' | transloco" target="_blank" rel="noopener">{{'result.hub' | transloco }}</a>
      <p class="d-inline">{{'result.equal.desc2'| transloco }}</p>
    </div>
  </div>
</div>
