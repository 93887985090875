import {AbstractControlState, FormGroupControls, updateGroup, validate} from 'ngrx-forms';
import {Grocery, School} from '../models';
import {isRequired, maximum, withinRange} from './utils';
import {RootForm} from './root.reducer';

export const initialGroceryState = {
    cost: null,
};

export const groceryGroupValidation = (rootControl) => updateGroup<Grocery>({
    cost: (cost: AbstractControlState<number>) =>
        validate<number>(
            cost,
            isRequired(rootControl.config.controls.groceryRequired.value),
            maximum(rootControl.config.controls.maxExpenses.value)
        ),
});
