import { updateGroup, validate, FormArrayState, FormGroupControls } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { Contribution, PriceEntity } from '../models';
import { RootForm } from './root.reducer';

const TRANSLATION_SCOPE = 'form.contribution';
const TRANSLATION_SCOPE_TITLE = 'title';
const TRANSLATION_SCOPE_DECS = 'desc';

const getTranslation = (key) => `${TRANSLATION_SCOPE}.${key}.${TRANSLATION_SCOPE_TITLE}`;
const getTranslationDesc = (key) => `${TRANSLATION_SCOPE}.${key}.${TRANSLATION_SCOPE_DECS}`;

export const initialContributionState = {
    scholarship: null,
    grant: null,
    other: null,
    savings: null,
    income: [
        {
            name: '', value: null, isDefault: false,
            nameInputTranslationScope: 'form.contribution.jobTitle',
            priceInputTranslationScope: 'form.contribution.jobPrice'
        },
    ]
};

export const contributionGroupValidation = (rootControl: FormGroupControls<RootForm>) => updateGroup<Contribution>({});
