import { createAction, props } from '@ngrx/store';

export const setActivePage = createAction(
    'setActivePage',
    props<{ activePage: number }>()
);

export const incrementActivePage = createAction('incrementActivePage');

export const setValidatedToPage = createAction(
    'setValidatedToPage',
    props<{ ValidatedToPage: number }>()
);

export const incrementValidatedToPage = createAction('incrementValidatedToPage');

export const setTotal = createAction(
  'setTotal',
  props<{ TotalCost: number, TotalContribution: number }>()
);
