<!-- Modal --> 
<ng-template #sources>
  <div class="modal-header">
    <h3 class="pull-left source-modal-header" id="sources-title">{{ 'legal.modal_header' | transloco }}</h3>
    <button type="button" id="sources-close" class="close pull-right" [attr.aria-label]="'legal.modal_close' | transloco" (click)="modalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="sources-description" [innerHTML]='"legal.modal_body" | transloco '></div>
</ng-template>

<footer role="contentinfo" class="td-padding-vert-0" *transloco="let t; read: 'footer'">
  <div class="td-row">
    <div class="col offset-2 col-8 offset-md-3 col-md-6 mt-5 mb-5">
      <p class="td-legal-copy">
        {{ 'legal.disclaimer' | transloco }}
        <!-- Button trigger modal -->
        <a href="javascript:;"
           (click)="openModal(sources);"
           role="button"
           [attr.aria-label]='"legal.clickherearia" | transloco '>
          {{ 'legal.clickhere' | transloco }}
        </a>.
      </p>
    </div>
  </div>

  <div class="td-row">
    <div class="td-footer-content td-col-xs-12 td-bg-colour-6 pb-5">
      <div class="td-container">
        <p class="td-footer-heading td-copy-white td-copy-align-centre">
          {{ t('needToTalk') }}
          <a href="https://www.td.com/ca/en/personal-banking/contact-us/" target="_blank"
            class="td-contact-link td-link-nounderline td-link-standalone" rel="noopener">
            {{ t('contactUs') }}
            <span data-msg="Link Icon Chevron" class="td-link-lastword">
              <span class="td-link-lastword">
                <span class="td-icon td-icon-rightCaret" aria-hidden="true">
                </span>
              </span>
            </span>
          </a>
        </p>
        <div class="td-footer-social td-copy-align-centre pb-0">
          <p class="td-footer-social-heading">
            {{ t('connectWithTD') }}
          </p>
          <ul>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon1Description" href="https://twitter.com/td_canada"
                target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-twitter"></span>
                  <span class="td-forscreenreader" id="icon1Description">Twitter</span>
                </div>
              </a>
            </li>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon2Description"
                href="https://facebook.com/tdbankgroup/" target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-facebook"></span>
                  <span class="td-forscreenreader" id="icon2Description">Facebook</span>
                </div>
              </a>
            </li>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon3Description"
                href="https://www.instagram.com/TD_Canada/" target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-instagram"></span>
                  <span class="td-forscreenreader" id="icon3Description">Instagram</span>
                </div>
              </a>
            </li>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon4Description" href="https://www.youtube.com/tdcanada"
                target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-youtube"></span>
                  <span class="td-forscreenreader" id="icon4Description">YouTube</span>
                </div>
              </a>
            </li>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon5Description"
                href="https://www.linkedin.com/company/td" target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-linkedin"></span>
                  <span class="td-forscreenreader" id="icon5Description">LinkedIn</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="td-footer-content td-col-xs-12 td-bg-colour-5 pb-5 hide-for-print">
      <div class="td-container">
        <div class="td-footer-links td-copy-align-centre td-copy-white">
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://www.td.com/privacy-and-security/privacy-and-security/index.jsp">
            {{ t('privacySecurity') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://www.td.com/to-our-customers/">
            {{ t('legal') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="http://www.tdcanadatrust.com/customer-service/accessibility/accessibility-at-td/index.jsp">
            {{ t('accessibility') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://www.td.com/about-tdbfg/our-business/index.jsp">
            {{ t('aboutUs') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://jobs.td.com/en-CA/">
            {{ t('hiring') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://www.td.com/ca/en/personal-banking/manage-content-preferences/">
            {{ t('adChoice') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>