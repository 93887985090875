<div class="py-5 hide-for-print" id="school">
    <div class="w-100">
        <div class="container-fluid px-0">
            <div class="row">
                <!-- Title Offset -->
                <div class="col-12 pl-lg-5 offset-sm-0 offset-md-5 text-center mb-3">
                    <h3>{{ 'form.costs.school.title' | transloco }}</h3>
                </div>
            </div>
            <form [ngrxFormState]="schoolForm$ | async" class="my-5 school-form-container">
                <app-form-input [formControlState]="(schoolForm$ | async).controls.tuition" [isCurrency]="true"
                    translationScope="form.costs.school.tuition">
                </app-form-input>
            </form>
            <div class="row">
                <div class="col-12 col-sm-8 pl-lg-5 offset-sm-0 offset-md-7 text-center next-btn-wrapper mt-5">
                    <button class="btn btn-block td-btn-primary-light" class="btn btn-block td-btn-primary-light"
                        data-analytics-click="tdct:p:student:budget:calculator:next:step" [disabled]="!canAdvance"
                        (click)="advanceFormState()" data-analytics-click="tdct:p:student:budget:calculator:next:step">
                        {{ 'form.btn.next' | transloco }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>