import { AbstractControlState, FormGroupControls, FormGroupState, updateGroup, validate } from 'ngrx-forms';
import { maximum, isRequired } from './utils';
import { Housing, HousingChoice } from '../models';
import { RootForm } from './root.reducer';

export const initialHousingState = {
    choice: HousingChoice.none,
    home: {
        additionalExpenses: null,
    },
    residence: {
        cost: null,
        mealPlan: null,
        additionalExpenses: null,
    },
    renting: {
        monthlyCost: null,
        additionalExpenses: null,
    }
};

export const housingGroupValidation = (rootControl: FormGroupControls<RootForm>) => updateGroup<Housing>({
    choice: (choice: AbstractControlState<HousingChoice>) =>
        validate<HousingChoice>(
            choice,
            (value: HousingChoice) => {
                return (!rootControl.config.controls.housingChoiceRequired.value || value !== HousingChoice.none)
                    ? null : {noHousingChoice: []};
            }
        ),
    home: (home) =>
        updateGroup({
            additionalExpenses: (additionalExpenses: AbstractControlState<number>) =>
                validate<number>(
                    additionalExpenses,
                    isRequired(false),
                    maximum(rootControl.config.controls.maxHousing.value)
                ),
        })(home),
    residence: (residence) =>
        updateGroup({
            cost: (cost: AbstractControlState<number>) =>
                validate<number>(
                    cost,
                    isRequired(false),
                    maximum(rootControl.config.controls.maxHousing.value)
                ),
            mealPlan: (cost: AbstractControlState<number>) =>
                validate<number>(
                    cost,
                    isRequired(false),
                    maximum(rootControl.config.controls.maxMealPlan.value)
                ),
            additionalExpenses: (tuition: AbstractControlState<number>) =>
                validate<number>(
                    tuition,
                    isRequired(false),
                    maximum(rootControl.config.controls.maxExpenses.value)
                ),
        })(residence),
    renting: (renting) =>
        updateGroup({
            monthlyCost: (monthlyCost: AbstractControlState<number>) =>
                validate<number>(
                    monthlyCost,
                    isRequired(false),
                    maximum(rootControl.config.controls.maxHousing.value)
                ),
            additionalExpenses: (tuition: AbstractControlState<number>) =>
                validate<number>(
                    tuition,
                    isRequired(false),
                    maximum(rootControl.config.controls.maxHousing.value)
                ),
        })(renting),
});
