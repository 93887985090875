import {AbstractControlState, FormArrayState, updateArray, updateGroup, validate} from 'ngrx-forms';

import {Additional, PriceEntity} from '../models';
import {isRequired, maximum} from './utils';

const TRANSLATION_SCOPE = 'form.costs.expenses.additional';

const getTranslation = (key) => `${TRANSLATION_SCOPE}.${key}`;

export const initialAdditionalState = {
    additionalCost: [
        {name: getTranslation('textbooks'), value: null, desc: null, isDefault: true},
        {name: getTranslation('furniture'), value: null, desc: null, isDefault: true},
    ]
};

const additionalCostEntityValidation = (rootControl) => updateGroup<PriceEntity>({
    value: (value: AbstractControlState<number>) =>
        validate<number>(
            value,
            isRequired(false),
            maximum(rootControl.config.controls.maxExpenses.value)
        ),
});

export const additionalGroupValidation = (rootControl) => updateGroup<Additional>({
    additionalCost: (additionalCost: FormArrayState<PriceEntity>) => updateArray<PriceEntity>(
        additionalCostEntityValidation(rootControl)
    )(additionalCost)
});

