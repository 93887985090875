import Sticky from 'sticky-js';
import { Component, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Global } from '../../models';
import { AppState, incrementValidatedToPage, setActivePage, incrementActivePage } from '../../store';
import * as formPages from '../form-pages';
import { scrollTo } from '../../util/scrollTo'

@Component({
  selector: 'app-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss']
})
export class FormContainerComponent implements OnInit {
  private appStore: Store<AppState>;
  global$: Observable<Global>;
  activePage: number;
  ValidatedToPage: number;
  formPages = formPages;
  sticky = new Sticky('.sticky-chart', {
    'stickyContainer': '.sticky-chart-container',
    'stickyFor': 767,
    'marginTop': 220,
    'wrap': true,
  });
  pageOrder$: Observable<string[]>;
  pageOrder: string[];
  scrollTo: any;

  incrementValidatedToPage() {
    this.appStore.dispatch(incrementValidatedToPage())
  }

  setActivePage(newIndex: number) {
    let vm = this;
    let isInBounds = (newIndex <= vm.ValidatedToPage)
    if (isInBounds) {
      let activePage: number = newIndex;
      vm.appStore.dispatch(setActivePage({ activePage: activePage }))
    }
  }

  constructor(private store: Store<AppState>) {
    this.appStore = store;
    this.global$ = store.pipe(
      select(state => <Global>state.global),
    );
    this.pageOrder$ = this.appStore.pipe(
      select(state => <string[]>state.global.pageOrder),
    );
    this.scrollTo = scrollTo;
  }

  ngOnInit(): void {
    let vm = this;

    vm.pageOrder$.subscribe(async (pageOrder) => {
      vm.pageOrder = pageOrder
    })

    vm.global$.subscribe(async (globals) => {
      let oldIndex = vm.activePage || 0
      let newIndex = globals.activePage || 0
      // update
      vm.activePage = newIndex
      vm.ValidatedToPage = globals.ValidatedToPage

      this.sticky.update()

      // Wait for the view to render before scrolling
      // the number here is arbitrary but this works
      await vm.delay(100);

      if (oldIndex !== newIndex) {
        vm.triggerScroll(newIndex)
      }
    })

    vm.appStore.subscribe(() => {
      // when form changes, so does the sticky
      this.sticky.update()
    })
  }

  triggerScroll(index) {
    if (this.ValidatedToPage >= this.activePage) {
      const section = this.pageOrder[index]
      this.scrollTo(section)
    }
  }

  printPage() {
    window.print();
    return false;
  }

  delay(ms: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
