<div class="py-5 hide-for-print" id="expenses">
  <div class="w-100">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-12 pl-lg-5 offset-sm-0 offset-md-5 text-center">
          <h3>{{ 'form.costs.expenses.title' | transloco }}</h3>
        </div>
      </div>
      <div [ngSwitch]></div>
      <form [ngrxFormState]="expensesForm$ | async" class="my-5"
        *transloco="let t; read 'form.costs.expenses.grocery.cost'">
        <label>
          {{ t("title") }}
        </label>
        <p>
          <small class="text-muted helper text">
            {{ t("desc") }}
          </small>
        </p>

        <div class="radio-bar">

          <input type="radio" id="grocery-choices-snack"
            [ngrxFormControlState]="(expensesForm$ | async).controls.grocery.controls.cost"
            [value]="groceryChoices[0]"
            [attr.aria-selected]="groceryChoices[0] == (expensesForm$ | async).controls.grocery.controls.cost.value"
            aria-labelledby="grocery-choices-snack-sr"
          />
          <label for="grocery-choices-snack" id="grocery-choices-snack-sr" class="btn">
            {{ t("choices.snack") }} <br />
            ${{ groceryChoices[0] }}{{ t("choices.period") }}
          </label>

          <input type="radio" id="grocery-choices-fewMeals"
            [ngrxFormControlState]="(expensesForm$ | async).controls.grocery.controls.cost"
            [value]="groceryChoices[1]"
            [attr.aria-selected]="groceryChoices[1] == (expensesForm$ | async).controls.grocery.controls.cost.value"
            aria-labelledby="grocery-choices-fewMeals-sr"
          />
          <label for="grocery-choices-fewMeals" id="grocery-choices-snack-fewMeals-sr" class="btn">
            {{ t("choices.fewMeals") }}<br />
            ${{ groceryChoices[1] }}{{ t("choices.period") }}
          </label>

          <input type="radio" id="grocery-choices-everyday"
            [ngrxFormControlState]="(expensesForm$ | async).controls.grocery.controls.cost"
            [value]="groceryChoices[2]"
            [attr.aria-selected]="groceryChoices[2] == (expensesForm$ | async).controls.grocery.controls.cost.value"
            aria-labelledby="grocery-choices-everyday-sr"
          />
          <label for="grocery-choices-everyday" id="grocery-choices-snack-everyday-sr" class="btn">
            {{ t("choices.everyday") }}<br />
            ${{ groceryChoices[2] }}{{ t("choices.period") }}
          </label>

        </div>

        <app-form-input
          [formControlState]="(expensesForm$ | async).controls.grocery.controls.cost"
          [isCurrency]="true"
          translationScope="form.costs.expenses.grocery.cost.input"
        ></app-form-input>
      </form>

      <form [ngrxFormState]="expensesForm$ | async" class="my-5"
        *transloco="let t; read 'form.costs.expenses.entertainment.cost'">
        <label>
          {{ t("title") }}
        </label>
        <p>
          <small class="text-muted helper text">
            {{ t("desc") }}
          </small>
        </p>

        <div class="radio-bar">

          <input type="radio" id="entertainment-choices-once"
            [ngrxFormControlState]="(expensesForm$ | async).controls.entertainment.controls.cost"
            [value]="entertainmentChoices[0]"
            [attr.aria-selected]="entertainmentChoices[0] == (expensesForm$ | async).controls.entertainment.controls.cost.value"
            aria-labelledby="entertainment-choices-once-sr"
          />
          <label for="entertainment-choices-once" id="entertainment-choices-once-sr" class="btn">
            {{ t("choices.once") }}<br />
            ${{ entertainmentChoices[0] }}{{ t("choices.period") }}
          </label>

          <input type="radio" id="entertainment-choices-fewTimes"
            [ngrxFormControlState]="(expensesForm$ | async).controls.entertainment.controls.cost"
            [value]="entertainmentChoices[1]"
            [attr.aria-selected]="entertainmentChoices[1] == (expensesForm$ | async).controls.entertainment.controls.cost.value"
            aria-labelledby="entertainment-choices-fewTimes-sr"
          />
          <label for="entertainment-choices-fewTimes" id="entertainment-choices-fewTimes-sr" class="btn">
            {{ t("choices.fewTimes") }}<br />
            ${{ entertainmentChoices[1] }}{{ t("choices.period") }}
          </label>

          <input type="radio" id="entertainment-choices-everyday"
            [ngrxFormControlState]="(expensesForm$ | async).controls.entertainment.controls.cost"
            [value]="entertainmentChoices[2]"
            [attr.aria-selected]="entertainmentChoices[2] == (expensesForm$ | async).controls.entertainment.controls.cost.value"
            aria-labelledby="entertainment-choices-everyday-sr"
          />
          <label for="entertainment-choices-everyday" id="entertainment-choices-everyday-sr" class="btn">
            {{ t("choices.everyday") }}<br />
            ${{ entertainmentChoices[2] }}{{ t("choices.period") }}
          </label>

        </div>

        <app-form-input [formControlState]="(expensesForm$ | async).controls.entertainment.controls.cost"
          [isCurrency]="true" translationScope="form.costs.expenses.entertainment.cost.input">
        </app-form-input>
      </form>

      <div>
        <label id="form.costs.expenses.commonCost.title.title" for="common-expenses-form">
          {{ 'form.costs.expenses.commonCost.title' | transloco }} &nbsp;
        </label>
        <p>
          <small class="text-muted helper text">
            {{ 'form.costs.expenses.commonCost.desc' | transloco }}
          </small>
        </p>
        <form [ngrxFormState]="expensesForm$ | async" class="mb-5" id="common-expenses-form">
          <app-dynamic-input-form
            [formState]="(expensesForm$ | async).controls.common.controls.commonCost.controls"
            [addField]="addCommonExpense"
            [removeField]="removeCommonExpense"
            [analyticsAddMore]="'tdct:p:student:budget:calculator:additional:expenses:common'"
          ></app-dynamic-input-form>
        </form>
      </div>

      <div>
        <label>
          {{ 'form.costs.expenses.additionalCost.title' | transloco }}
        </label>
        <p>
          <small class="text-muted helper text">
            {{ 'form.costs.expenses.additionalCost.desc' | transloco }}
          </small>
        </p>
        <form [ngrxFormState]="expensesForm$ | async" class="mb-5">
          <app-dynamic-input-form
            [formState]="(expensesForm$ | async).controls.additional.controls.additionalCost.controls"
            [addField]="addAdditionalExpense"
            [removeField]="removeAdditionalExpense"
            [analyticsAddMore]="'tdct:p:student:budget:calculator:additional:expenses'"
          ></app-dynamic-input-form>
        </form>
      </div>

      <div class="row">
        <div class="col-12 col-sm-8 pl-lg-5 offset-sm-0 offset-md-7 text-center next-btn-wrapper mt-5">
          <button class="btn btn-block td-btn-primary-light" class="btn btn-block td-btn-primary-light"
            [disabled]="!canAdvance" (click)="advanceFormState()"
            data-analytics-click="tdct:p:student:budget:calculator:next:step">
            {{ 'form.btn.next' | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>