import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-drawer-menu',
    templateUrl: './drawer-menu.component.html',
    styleUrls: ['./drawer-menu.component.scss']
})
export class DrawerMenuComponent implements OnInit {

    @Input() open: boolean;
    @Output() closeDrawer = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
        const focusableElements = 'button, [href], [tabindex]:not([tabindex="-1"])';
        const modal = document.querySelector('.drawer-menu');
        const focusableContentInit = modal.querySelectorAll(focusableElements);
        const firstFocusableElement = focusableContentInit[0];
        (firstFocusableElement as HTMLElement)?.focus();

        document.addEventListener('keydown', function(e) {
            const focusableElements = 'button, [href], [tabindex]:not([tabindex="-1"])';
            const modal = document.querySelector('.drawer-menu');
            const isLangOpen = modal.querySelectorAll('button.dropdown-toggle[aria-expanded="true"]').length > 0;

            const focusableContentDynamic = modal.querySelectorAll(focusableElements);

            const focusableContentList = isLangOpen ? focusableContentDynamic : focusableContentInit

            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    (focusableContentList[focusableContentList.length - 1] as HTMLElement)?.focus();
                }
            } else {
                if (document.activeElement === focusableContentList[focusableContentList.length - 1]) {
                    (focusableContentList[0] as HTMLElement)?.focus();
                    e.preventDefault();
                }
            }
        });
    }

    onClose(): void {
        this.open = false;
        this.closeDrawer.emit();
    }
}
