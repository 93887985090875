import {AbstractControlState, updateGroup, validate} from 'ngrx-forms';

import { Entertainment } from '../models';
import {isRequired, maximum} from './utils';

export const initialEntertainmentState = {
    cost: null,
};

export const entertainmentGroupValidation = (rootControl) => updateGroup<Entertainment>({
    cost: (cost: AbstractControlState<number>) =>
        validate<number>(
            cost,
            isRequired(rootControl.config.controls.entertainmentRequired.value),
            maximum(rootControl.config.controls.maxExpenses.value)
        ),
});
