import {FormGroupControls, FormGroupState, updateGroup} from 'ngrx-forms';
import {Additional, Common, Entertainment, Expenses, Grocery} from '../models';
import {RootForm} from './root.reducer';
import {groceryGroupValidation, initialGroceryState} from './grocery.reducer';
import {entertainmentGroupValidation, initialEntertainmentState} from './entertainment.reducer';
import {commonGroupValidation, initialCommonState} from './common.reducer';
import {additionalGroupValidation, initialAdditionalState} from './additional.reducer';

export const initialExpensesState = {
    grocery: initialGroceryState,
    entertainment: initialEntertainmentState,
    common: initialCommonState,
    additional: initialAdditionalState,
};

export const expensesGroupValidation = (rootControl: FormGroupControls<RootForm>) => updateGroup<Expenses>({
    grocery: (grocery: FormGroupState<Grocery>, rootForm) =>
        groceryGroupValidation(rootControl)(grocery),
    entertainment: (entertainment: FormGroupState<Entertainment>, rootForm) =>
        entertainmentGroupValidation(rootControl)(entertainment),
    common: (common: FormGroupState<Common>, rootForm) =>
        commonGroupValidation(rootControl)(common),
    additional: (additional: FormGroupState<Additional>, rootForm) =>
        additionalGroupValidation(rootControl)(additional),
});
