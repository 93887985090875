import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroupState } from 'ngrx-forms';
import { Global, School } from 'src/app/models';
import { Store, select } from '@ngrx/store';
import { AppState, InvalidFieldsSelector, incrementValidatedToPage, incrementActivePage, setActivePage } from 'src/app/store';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss']
})
export class SchoolComponent implements OnInit {
  private appStore: Store<AppState>;
  schoolForm$: Observable<FormGroupState<School>>;
  global$: Observable<Global>;
  appErrors$: Observable<number>;
  canAdvance: number;
  ValidatedToPage: number;

  constructor(private store: Store<AppState>, private invalidFieldsSelector: InvalidFieldsSelector) {
    this.appStore = store;
    this.global$ = store.pipe(
      select(state => state.global as Global),
    );
    this.appErrors$ = invalidFieldsSelector.appErrors$;
    this.schoolForm$ = store.pipe(
      select(state => state.form.controls.school as FormGroupState<School>),
    );

    const vm = this;
    this.global$.subscribe(async (globals) => {
      vm.ValidatedToPage = globals.ValidatedToPage
    });
  }

  advanceFormState(): void {
    const vm = this;
    // increment store
    if (this.canAdvance) {
      if (vm.ValidatedToPage <= 0) vm.appStore.dispatch(incrementValidatedToPage());
      vm.appStore.dispatch(setActivePage({ activePage: 1 }));
    }
  }

  setupErrorWatchers(vm): void {
    vm.canAdvance = 0;
    vm.schoolForm$.subscribe((form) => {
      // is there any user entry yet?
      // no validation has been triggered yet
      form.isPristine ? vm.canAdvance = 0 : vm.canAdvance = 1;
      // if there is a problem, disable
      Object.keys(form.errors).forEach(formElement => {
        vm.canAdvance = 0;
      });
    });
  }

  incrementValidatedToPage() {
    this.appStore.dispatch(incrementValidatedToPage())
  }

  setActivePage(newIndex: number) {
    let vm = this;
    let isInBounds = (newIndex <= vm.ValidatedToPage)
    if (isInBounds) {
      let activePage: number = newIndex;
      vm.appStore.dispatch(setActivePage({ activePage: activePage }))
    }
  }

  ngOnInit(): void {
    const vm = this;
    this.setupErrorWatchers(vm);
  }

}
