const scrollTo = (section: string) => {
    const element = document.getElementById(section);
    const headerOffset = 160;
    const elementPosition = window.pageYOffset + element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

export { scrollTo }
