<section class="suggestions td-fixed-grid-block-3-column td-bg-colour-1 hide-for-print" *ngIf="ValidatedToPage > 3">
  <div class="td-col-sm-12 mb-5 mt-2">
    <h2>{{'suggestions.title' | transloco}}</h2>
  </div>
  <div class="td-3-column-grid-blocks">
    <div class="td-col-md-12 p-0" id="grid-blocks">
      <div
          *ngFor="let suggestion of suggestions; trackBy: suggestion"
          class="td-col-sm-4 td-extend-left td-extend-right td-3-column-grid">
        <div *transloco="let t; read: 'suggestions.' + suggestion">
          <a [href]="t('link')"
              target="_blank" class="td-copy-white td-no-underline">
            <picture>
              <img alt="Image Block"
                  [src]="t('img')">
            </picture>
            <div class="grid-over">
              <div class="grid-over-title">
                {{t('title')}}
              </div>
              <div class="grid-over-copy">
                {{t('desc')}}
              </div>
            </div>
            <div class="grid-hover">
              <div>
                <h3 class="td-font-24 td-margin-top-0 td-margin-sm-bottom-10 td-margin-md-bottom-20">
                  {{t('title')}}
                </h3>
                <p class="text-center td-margin-bottom-40 td-margin-sm-bottom-20 td-line-height-small">
                  {{t('desc')}}
                </p>
                <span class="td-icon-wrapper-white td-interactive-icon overlay-close margin-top-20">
                    <span class="td-icon td-icon-rightCaret"></span>
                  </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
