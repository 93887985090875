import {
    createFormGroupState,
    createFormStateReducerWithUpdate,
    FormGroupState,
    updateGroup,
} from 'ngrx-forms';
import {initialGlobalState, globalReducer} from './global.reducer';
import {initialConfigState, configGroupValidation} from './config.reducer';
import {Global, Config, Expenses, School, Housing, Contribution} from '../models';
import {expensesGroupValidation, initialExpensesState} from './expenses.reducer';
import {initialSchoolState, schoolGroupValidation} from './school.reducer';
import {housingGroupValidation, initialHousingState} from './housing.reducer';
import {contributionGroupValidation, initialContributionState} from './contribution.reducer';

/**
 * TODO add Contributions
 * so that this looks like
 * ```ts
 *   export interface RootForm {
 *       school: School;
 *       housing: Housing;
 *       expenses: Expenses;
 *       contributions: Contributions;
 *       config: Config;
 *   }
 * ```
 */
export interface RootForm {
    school: School;
    housing: Housing;
    expenses: Expenses;
    contribution: Contribution;
    config: Config;
}

export const initialFormState = createFormGroupState<RootForm>('form', {
    school: initialSchoolState,
    housing: initialHousingState,
    expenses: initialExpensesState,
    contribution: initialContributionState,
    config: initialConfigState,
});

export const formReducer = createFormStateReducerWithUpdate<RootForm>(
    updateGroup<RootForm>({
            config: configGroupValidation,
        },
        {
            school: (school: FormGroupState<School>, rootForm) =>
                schoolGroupValidation(rootForm.controls)(school),
            housing: (housing: FormGroupState<Housing>, rootForm) =>
                housingGroupValidation(rootForm.controls)(housing),
            expenses: (expenses: FormGroupState<Expenses>, rootForm) =>
                expensesGroupValidation(rootForm.controls)(expenses),
            contribution: (contribution: FormGroupState<Contribution>, rootForm) =>
            contributionGroupValidation(rootForm.controls)(contribution),
        }),
);

export interface AppState {
    global: Global;
    form: FormGroupState<RootForm>;
}

export const initialState: AppState = {
    global: initialGlobalState,
    form: initialFormState,
};

export const rootReducer = {
    global: globalReducer,
    form: formReducer,
};
