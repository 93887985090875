import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    FormGroupState,
    AbstractControlState,
} from 'ngrx-forms';
import { Observable } from 'rxjs';

import { AppState, RootForm } from './root.reducer';
import { HousingChoice } from '../models';

@Injectable()
export class SumCostsSelector {
    schoolSum$: Observable<number>;
    housingSum$: Observable<number>;

    constructor(private store: Store<AppState>) {
        this.schoolSum$ = store.pipe(
            select((state) => {
                return sumValues(state.form.controls.school)
            })
        )
        this.housingSum$ = store.pipe(
            select((state) => {
                var housingSum$ = 0;
                let choice = (state.form.controls.housing.controls.choice.value) + 0;
                switch (choice) {
                    case (HousingChoice.home):
                        housingSum$ = sumValues(state.form.controls.housing.controls.home);
                    case (HousingChoice.residence):
                        housingSum$ = sumValues(state.form.controls.housing.controls.residence);
                    case (HousingChoice.renting):
                        housingSum$ = sumValues(state.form.controls.housing.controls.renting);
                    default:
                        housingSum$ = null;
                };
                return housingSum$;
            })
        );
    }
}

function sumValues(control: AbstractControlState<any>): number {
    const subControl = (control as FormGroupState<any>).controls;
    if (control.isPristine) {
        return 0;
    }
    if (!subControl) {
        return typeof control.value === 'number' ? control.value : 0;
    }
    return Object.keys(subControl).reduce((value, key) => {
        return sumValues(subControl[key]) + value;
    }, 0);
}

@Injectable()
export class InvalidFieldsSelector {
    appErrors$: Observable<number>;
    schoolErrors$: Observable<number>;
    housingErrors$: Observable<number>;
    expensesErrors$: Observable<number>;
    configErrors$: Observable<number>;

    constructor(private store: Store<AppState>) {
        this.appErrors$ = store.pipe(
            select(state => countValidationErrors(state.form)),
        );
        this.schoolErrors$ = store.pipe(
            select(state => countValidationErrors(state.form.controls.school)),
        );
        this.housingErrors$ = store.pipe(
            select(state => countValidationErrors(state.form.controls.housing)),
        );
        this.expensesErrors$ = store.pipe(
            select(state => countValidationErrors(state.form.controls.expenses)),
        );
        this.configErrors$ = store.pipe(
          select(state => countValidationErrors(state.form.controls.contribution)),
        );
        this.configErrors$ = store.pipe(
            select(state => countValidationErrors(state.form.controls.config)),
        );
    }
}

function countValidationErrors(control: AbstractControlState<any>): number {
    const subControl = (control as FormGroupState<any>).controls;
    if (control.isPristine) {
        return 0;
    }
    if (!subControl) {
        return Object.keys(control.errors).length;
    }
    return Object.keys(subControl).reduce((errors, key) => {
        return countValidationErrors(subControl[key]) + errors;
    }, 0);
}
