import {AbstractControlState, FormArrayState, updateArray, updateGroup, validate} from 'ngrx-forms';
import {Common, PriceEntity} from '../models';
import {isRequired, maximum} from './utils';

const TRANSLATION_SCOPE = 'form.costs.expenses.common';

const getTranslation = (key) => `${TRANSLATION_SCOPE}.${key}`;

export const initialCommonState = {
    commonCost: [
        {name: getTranslation('travelHome'), value: null, desc: null, isDefault: true},
        {name: getTranslation('phonePlan'), value: null, desc: null, isDefault: true},
        {name: getTranslation('shopping'), value: null, desc: null, isDefault: true},
        {name: getTranslation('carInsurance'), value: null, desc: null, isDefault: true},
        {name: getTranslation('gas'), value: null, desc: null, isDefault: true},
    ]
};

const commonCostEntityValidation = (rootControl) => updateGroup<PriceEntity>({
    value: (value: AbstractControlState<number>) =>
        validate<number>(
            value,
            isRequired(false),
            maximum(rootControl.config.controls.maxExpenses.value)
        ),
});

export const commonGroupValidation = (rootControl) => updateGroup<Common>({
    commonCost: (commonCost: FormArrayState<PriceEntity>) => updateArray<PriceEntity>(
        commonCostEntityValidation(rootControl)
    )(commonCost)
});
