import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState, setActivePage } from 'src/app/store';
import { Global } from 'src/app/models/global';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss'],
})
export class SuggestionComponent implements OnInit {
  private appStore: Store<AppState>;
  global$: Observable<Global>;
  ValidatedToPage: number;
  TotalCost: number;
  TotalContribution: number;

  // Keys corresponding to the suggestions in translation file
  suggestions = ['appointment', 'savings', 'scholarships'];

  constructor(private store: Store<AppState>) {
    this.appStore = store;
    this.global$ = store.pipe(select((state) => <Global>state.global));
  }

  ngOnInit(): void {
    let vm = this;
    var difference = 0;

    vm.global$.subscribe((globals) => {
      vm.ValidatedToPage = globals.ValidatedToPage;
      vm.TotalCost = globals.TotalCost
      vm.TotalContribution = globals.TotalContribution
      difference = vm.TotalCost - vm.TotalContribution
      if (difference === 0) {
        // Balanced
        vm.suggestions = ['appointment', 'credit', 'scholarships'];
      } else if (difference > 0) {
        // Deficit
        vm.suggestions = ['appointment', 'credit', 'aid'];
      } else if (difference < 0) {
        // Surplus
        vm.suggestions = ['appointment', 'savings', 'scholarships'];
      }
    });
  }

  trackByIndex(index: number): any {
    return index;
  }
}
