export enum HousingChoice {
    "none",
    "home",
    "residence",
    "renting"
}

export interface Housing {
    choice: HousingChoice;
    home: {
        additionalExpenses: number;
    };
    residence: {
        cost: number;
        mealPlan: number;
        additionalExpenses: number;
    };
    renting: {
        monthlyCost: number;
        additionalExpenses: number;
    };
}
