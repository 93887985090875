import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { InvalidFieldsSelector, AppState } from '../store';
import { Global } from '../models';
import { Store, select } from '@ngrx/store';
import tms_tag from '../analytics/analytics';

@Component({
  selector: 'app-root',
  styleUrls: ['./root.component.scss'],
  template: `
      <!-- Fixed top header -->
      <app-top-nav></app-top-nav>

      <!-- Introduction text, scrolls off screen -->
      <div class="mt-5 pt-3 w-100 hide-for-print">
        <div id="banner-img">
          <h2>{{ 'page.intro-title' | transloco }}</h2>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-9 text-center py-4">
              {{ 'page.intro-header' | transloco }}
            </div>
          </div>
        </div>

        <div class="container">
          <hr class="td-thin-divider-line-1">
        </div>
      </div>

      <div class="w-100 sticky-progress-container">
        <!-- Top progress bar sticks under fixed header. -->
        <div class="sticky-top">
          <app-top-progress></app-top-progress>
        </div>

        <!-- Contains remainder of page content. -->
        <div class="main-content p-relative">

          <!-- Page content -->
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-suggestion></app-suggestion>
      <app-footer></app-footer>
    `,
})

export class RootComponent implements OnInit {
  public appErrors$: Observable<number>;
  public schoolErrors$: Observable<number>;
  public housingErrors$: Observable<number>;
  public expensesErrors$: Observable<number>;
  public configErrors$: Observable<number>;
  public topShadowCover: boolean;
  private global$: Observable<Global>;

  constructor(private invalidFieldsSelector: InvalidFieldsSelector, private store: Store<AppState>) {
    this.appErrors$ = invalidFieldsSelector.appErrors$;
    this.schoolErrors$ = invalidFieldsSelector.schoolErrors$;
    this.housingErrors$ = invalidFieldsSelector.housingErrors$;
    this.expensesErrors$ = invalidFieldsSelector.expensesErrors$;
    this.configErrors$ = invalidFieldsSelector.configErrors$;
    this.global$ = store.pipe(
      select(state => <Global>state.global),
    );
  }

  ngOnInit(): void {
    let vm = this;

    vm.global$.subscribe(async (globals) => {
      // Drive page event for analytics
      let currentPage = `student-budget-calculator-school-step${globals.activePage + 1}`
      tms_tag.pageName = currentPage
    })
  }
}
