import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { LanguageService } from '../../service/language.service'

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  @Input() wide: boolean;
  availableLangs: string[] | { id: string, label: string }[];
  activeLang: string;
  ariaExpanded: boolean;
  langUrls: {
    en: string;
    fr: string;
    // zh: string;
    // zt: string;
  };

  constructor(
    private translocoService: TranslocoService,
    private router: Router,
    private languageService: LanguageService
  ) {
    this.langUrls = {
      en: '/ca/en/personal-banking/student-budget-calculator/#/',
      fr: '/ca/fr/services-bancaires-personnels/calculateur-de-budget-etudiant/#/',
      // zh: '/ca/en/personal-banking/student-budget-calculator/#/?lang=zh',
      // zt: '/ca/en/personal-banking/student-budget-calculator/#/?lang=zt'
    }
    this.ariaExpanded = false;
  }

  ngOnInit(): void {
    const vm = this

    vm.activeLang = vm.languageService.getLang()
    vm.translocoService.setActiveLang(vm.activeLang)
    vm.availableLangs = vm.translocoService.getAvailableLangs()
  }

  getLangDescription(lang: string): string {
    switch (lang) {
      case 'en': return 'English';
      case 'fr': return 'Français';
      // case 'zh': return '语言：简体中文';
      // case 'zt': return '語言：繁體中文';
      default: return '';
    }
  }

  getLangDescriptionSr(lang: string): string {
    switch (lang) {
      case 'en': return 'Select Language: English';
      case 'fr': return 'Select Language: Français';
      // case 'zh': return 'Yǔyán: Jiǎntǐ zhōngwén';
      // case 'zt': return 'Yǔyán: Fántǐ zhōngwén';
      default: return '';
    }
  }

  getLangSr(lang: string): string {
    const vm = this;

    switch (lang) {
      case 'en': return vm.activeLang === 'en' ? 'English Link' : 'Lien anglais';
      case 'fr': return vm.activeLang === 'en' ? 'Français Link' : 'Lien français'
      // case 'zh': return 'Jiǎntǐ zhōngwén';
      // case 'zt': return 'Fántǐ zhōngwén';
      default: return '';
    }
  }

  changeLang(lang: string) {
    const vm = this;

    if (isDevMode()) {
      vm.translocoService.setActiveLang(lang)
      vm.activeLang = vm.translocoService.getActiveLang()
      vm.router.navigateByUrl( `/${lang}/?lang=${lang}/`)
    } else {
      // Is prod build.
      // Go to Different TD URL.
      window.location.href = vm.langUrls[lang]
    }
  }
}
