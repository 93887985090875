import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormGroupState, AbstractControlState, AddArrayControlAction, RemoveArrayControlAction } from 'ngrx-forms';
import { Observable } from 'rxjs';

import { AppState, InvalidFieldsSelector, incrementActivePage, incrementValidatedToPage } from '../store';
import { Additional, Costs, Expenses, Global, Housing, School } from '../models';

@Component({
  selector: 'app-costs',
  template: `
      <hr/>
      <!-- required demo -->
      <!--      <form [ngrxFormState]="costsForm$ | async" class="my-3">-->
      <!--        <input-->
      <!--            [ngrxFormControlState]="(costsForm$ | async).controls.firstName"-->
      <!--            name="form.costs.firstname"-->
      <!--            type="string"-->
      <!--            placeholder="firstName"-->
      <!--        />* &emsp;-->
      <!--        <input-->
      <!--            [ngrxFormControlState]="(costsForm$ | async).controls.lastName"-->
      <!--            name="form.costs.lastName"-->
      <!--            type="string"-->
      <!--            placeholder="lastName"-->
      <!--        />*-->
      <!--      </form>-->
      <app-finances-chart></app-finances-chart>
      <div [ngClass]="{'bg-success': true, 'bg-secondary': false}" class="py-5">
        <div class="w-100 p-relative m-0 p-0">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>{{ 'form.costs.title' | transloco }}</h3>
              </div>
            </div>
            <form [ngrxFormState]="schoolForm$ | async" class="my-3">
              <div class="col-12 col-sm-6 col-md-4 form-group td-form-group-padding mx-auto">
                <app-form-input
                    [isCurrency]="true"
                    [formControlState]="(schoolForm$ | async).controls.tuition"
                    translationScope="form.costs.school.tuition"
                ></app-form-input>
                canAdvance: {{ canAdvance }} | appErrors$: {{ appErrors$ | async }}
              </div>
              <app-dynamic-input-form
                  [formState]="(expensesForm$ | async).controls.additional.controls.additionalCost.controls"
                  [addField]="addAdditionalExpense"
                  [removeField]="removeAdditionalExpense"
              ></app-dynamic-input-form>
              <app-dynamic-input-form
                  [formState]="(expensesForm$ | async).controls.common.controls.commonCost.controls"
                  [addField]="addCommonExpense"
                  [removeField]="removeCommonExpense"
              ></app-dynamic-input-form>
            </form>
            <div class="row">
              <div class="col col-6 col-sm-4 mx-auto">
                <button
                    class="btn btn-block td-btn-primary-light"
                    [disabled]="!canAdvance"
                    (click)="advanceFormState()"
                >
                  {{ 'form.btn.next' | transloco }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-housing></app-housing>
      <app-contributions></app-contributions>
      <app-results></app-results>
      <app-suggestion></app-suggestion>
      <app-footer></app-footer>
    `,
})

export class CostsComponent implements OnInit {
  private appStore: Store<AppState>;
  global$: Observable<Global>;
  appErrors$: Observable<number>;
  // costsForm$: Observable<FormGroupState<Costs>>;
  JSON: JSON;
  canAdvance: number;
  schoolForm$: Observable<FormGroupState<School>>;
  housingForm$: Observable<FormGroupState<Housing>>;
  expensesForm$: Observable<FormGroupState<Expenses>>;
  expenses: FormGroupState<Expenses>;
  additional: FormGroupState<Additional>;

  addAdditionalExpense(): void {
    this.appStore.dispatch(
      new AddArrayControlAction(
        'form.expenses.additional.additionalCost',
        { name: '', value: '', isDefault: false }
      ));
  }

  removeAdditionalExpense(index: number): void {
    this.appStore.dispatch(
      new RemoveArrayControlAction(
        'form.expenses.additional.additionalCost',
        index
      ));
  }

  addCommonExpense(): void {
    this.appStore.dispatch(
      new AddArrayControlAction(
        'form.expenses.common.commonCost',
        { name: '', value: '', isDefault: false }
      ));
  }

  removeCommonExpense(index: number): void {
    this.appStore.dispatch(
      new RemoveArrayControlAction(
        'form.expenses.common.commonCost',
        index
      ));
  }

  advanceFormState(): void {
    const vm = this;
    // increment store
    alert('Go to next form page: ' + !!this.canAdvance);
    if (this.canAdvance) {
      vm.appStore.dispatch(incrementValidatedToPage());
      vm.appStore.dispatch(incrementActivePage());
    }
  }

  constructor(private store: Store<AppState>, private invalidFieldsSelector: InvalidFieldsSelector) {
    this.appStore = store;
    this.global$ = store.pipe(
      select(state => state.global as Global),
    );
    this.appErrors$ = invalidFieldsSelector.appErrors$;
    this.schoolForm$ = store.pipe(
      select(state => state.form.controls.school as FormGroupState<School>),
    );
    this.housingForm$ = store.pipe(
      select(state => state.form.controls.housing as FormGroupState<Housing>),
    );
    this.expensesForm$ = store.pipe(
      select(state => state.form.controls.expenses as FormGroupState<Expenses>),
    );
  }

  ngOnInit(): void {
    const vm = this;
    vm.canAdvance = 0;
    vm.schoolForm$.subscribe((form) => {
      // is there any user entry yet?
      // no validation has been triggered yet
      form.isPristine ? vm.canAdvance = 0 : vm.canAdvance = 1;
      // if there is a problem, disable
      Object.keys(form.errors).forEach(formElement => {
        vm.canAdvance = 0;
      }
      )
    })
  }
}
