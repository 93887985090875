<div class="w-100 bg-white progress-container sticky-progress"
    (scroll)="onScroll($event)"
    data-margin-top="69px">
    <div class="container" *transloco="let t; read: 'page.top-progress-titles'">
        <div class="row justify-content-center pt-3 pb-3 px-2">
            <ul class="col-12 text-center pt-3" id="progressbar">
                <li class="active cursor-pointer"
                    id="school"
                    (click)="setActivePage(0)"
                    (keydown.enter)="setActivePage(0)"
                    (keydown.space)="setActivePage(0)"
                    tabindex="0"
                    role="button">
                        <span>{{ t('school') }}</span>
                </li>

                <li [ngClass]="{'active': ValidatedToPage >= 1}"
                    id="housing"
                    class="cursor-pointer"
                    (click)="setActivePage(1)"
                    (keydown.enter)="setActivePage(1)"
                    (keydown.space)="setActivePage(1)"
                    tabindex="{{ ValidatedToPage >= 1 ? 0 : -1 }}"
                    role="button">
                        <span>{{ t('housing') }}</span>
                </li>

                <li [ngClass]="{'active': ValidatedToPage >= 2}"
                    id="expenses"
                    class="cursor-pointer"
                    (click)="setActivePage(2)"
                    (keydown.enter)="setActivePage(2)"
                    (keydown.space)="setActivePage(2)"
                    tabindex="{{ ValidatedToPage >= 2 ? 0 : -1 }}"
                    role="button">
                        <span>{{ t('expenses') }}</span>
                </li>

                <li [ngClass]="{'active': ValidatedToPage >= 3}"
                    id="contributions"
                    class="cursor-pointer"
                    (click)="setActivePage(3)"
                    (keydown.enter)="setActivePage(3)"
                    (keydown.space)="setActivePage(3)"
                    tabindex="{{ ValidatedToPage >= 3 ? 0 : -1 }}"
                    role="button">
                        <span>{{ t('contributions') }}</span>
                </li>

                <li [ngClass]="{'active': ValidatedToPage >= 4}"
                    id="results"
                    class="cursor-pointer"
                    (click)="setActivePage(4)"
                    (keydown.enter)="setActivePage(4)"
                    (keydown.space)="setActivePage(4)"
                    tabindex="{{ ValidatedToPage >= 4 ? 0 : -1 }}"
                    role="button">
                        <span>{{ t('results') }}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
