import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { Observable } from 'rxjs';
import { Global } from 'src/app/models';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
  private appStore: Store<AppState>;
  global$: Observable<Global>;
  difference: number;
  TotalCost: number;
  TotalContribution: number;

  constructor(private store: Store<AppState>) {
    this.appStore = store;
    this.global$ = store.pipe(
      select(state => <Global>state.global),
    );
    this.difference = 0;
  }

  ngOnInit(): void {
    let vm = this;
    vm.global$.subscribe((globals) => {
      vm.TotalCost = globals.TotalCost
      vm.TotalContribution = globals.TotalContribution
      vm.difference = vm.TotalCost - vm.TotalContribution;
    });


  }
}
