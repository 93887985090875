import { Component, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { InvalidFieldsSelector } from '../../store';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent {
    public appErrors$: Observable<number>;
    public drawerOpen: boolean;
    public devMode: boolean;

    constructor(private invalidFieldsSelector: InvalidFieldsSelector) {
        this.appErrors$ = invalidFieldsSelector.appErrors$;
        this.drawerOpen = false;
        this.devMode = isDevMode();
    }

    delay(ms: number): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    closeDrawer(): void {
        document.getElementById('nav-menu-btn').focus();
        this.drawerOpen = false;
    }

    openDrawer(): void {
        this.drawerOpen = true;
        this.delay(50); // Wait for HTML to be drawn
        document.getElementById('mobile-nav-close').focus();
    }

}
